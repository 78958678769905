import React from 'react';
import Header from './Header';
import Inventory from './Inventory';
import Order from './Order';
import sampleFishes from '../sample-fishes';
import Fish from './Fish';
import base from '../base';
import PropTypes from 'prop-types';

class App extends React.Component {

    state = {
        fishes: {},
        order: {}
    };

    static propTypes = {
        match: PropTypes.Object
    }

    // lifecycle method
    componentDidMount() {
        const { params } = this.props.match;
        // first reinstate our localStorage
        const localStorageRef = localStorage.getItem(params.storeId);
        if(localStorageRef) {
            console.log("restoring it!!!");
            console.log(JSON.parse(localStorageRef));
            this.setState({ order: JSON.parse(localStorageRef) }); 
        }
        this.ref = base.syncState(`${params.storeId}/fishes`, {
            context: this,
            state: 'fishes'
        });
    }
 
    // lifecycle method
    componentWillUnmount() {
        console.log("Unmounted!!!");
        base.removeBinding(this.ref);
    }

    // lifecycle method
    componentDidUpdate() {
        localStorage.setItem(this.props.match.params.storeId, JSON.stringify(this.state.order));
    }

    loadSampleFishes = () => {
        console.log("Loading Samples");
        this.setState({ fishes: sampleFishes });
    };

    addToOrder = (key) => {
        // 1. take a copy of state
        const order = { ...this.state.order };
        // 2. either add to the order, or update the number in our order
        order[key] = order[key] + 1 || 1;
        // 3. call setState to update our state object
        this.setState({ order });
    };

    removeFromOrder = (key) => {
        const order = { ...this.state.order }
        delete order[key]; // not setting to null b/c not deleting from firebase.
        this.setState({ order });
    };

    addFish = fish => {
        // 1. Take a copy of the existing state...
        const fishes = { ...this.state.fishes };
        // 2. Add our new fish to the fishes variable
        fishes[`fish${Date.now()}`] = fish;
        this.setState({
            fishes: fishes
        });
    };

    updateFish = (key, updatedFish) => {
        // 1. take a copy of the current state
        const fishes = { ...this.state.fishes };
        // 2. update that state
        fishes[key] = updatedFish;
        // 3. set that to state
        this.setState({ fishes });
    };

    deleteFish = (key) => {
        // 1. take a copy of state
        const fishes = { ...this.state.fishes };
        // 2. update the state
        fishes[key] = null;
        this.setState({ fishes });

    };

    render() {
        return (

           <div className="catch-of-the-day">
               <div className="menu">
                   <Header tagline="Fresh seafood market" />
                    <ul className="fishes">
                        {Object.keys(this.state.fishes).map(key => (
                            <Fish 
                                key={key} 
                                index={key}
                                details={this.state.fishes[key]} 
                                addToOrder={this.addToOrder}/>
                                ))}
   
                    </ul>
               </div>

                <Order 
                    fishes={this.state.fishes} 
                    order={this.state.order}
                    removeFromOrder={this.removeFromOrder} />
                <Inventory 
                    addFish={this.addFish} 
                    updateFish={this.updateFish}
                    deleteFish={this.deleteFish}
                    loadSampleFishes={this.loadSampleFishes}
                    fishes={this.state.fishes}/>

           </div>

        );
    }
}

export default App;

