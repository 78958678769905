import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    
        apiKey: "AIzaSyDz0Mz-zrDyBW-bOGIuvx_wVl0C5ib7stk",
        authDomain: "catch-of-the-day-jjlovaglio.firebaseapp.com",
        databaseURL: "https://catch-of-the-day-jjlovaglio-default-rtdb.firebaseio.com",
      
});

const base = Rebase.createClass(firebaseApp.database());

// this is a named export
export { firebaseApp };

// this is a default export
export default base;
